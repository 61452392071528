<template>
  <div class="menus">
	  <div id="">
	  	 <el-form  ref="queryForm"  v-hasPermi="['system:nav:list']" :inline="true" label-width="40px" style="width: 100%;text-align:left;">
					<el-form-item label="标题" prop="roleName" >
					  <el-input
					  v-model="resetuserName"
					    placeholder="请输入名称"
					    clearable
					    size="small"
					    style="width: 200px"
					    @keyup.enter.native="handleQuery"
					  />
					</el-form-item>
					<el-form-item label="状态" prop="status">
					  <el-select
					   v-model="resetstatus"
					    placeholder="请选择"
					    clearable
					    size="small"
					    style="width: 200px"
					  >
					    <el-option  value="0"  label="隐藏"> </el-option>
						  <el-option  value="1"  label="开启"> </el-option>
					  </el-select>
					</el-form-item>
			
					<el-form-item label="" prop="status">
					  <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery" v-hasPermi="['system:nav:list']">搜索</el-button>
					  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery" v-hasPermi="['system:nav:list']">重置</el-button>
					</el-form-item>
	
	  	 </el-form>
		 
	  </div>
      <div class="tian">
         <el-button type="primary"
			plain
			icon="el-icon-plus"
			size="mini" @click="addition()" style=" float:left;" v-hasPermi="['system:nav:add']" >添加
		 </el-button>
      </div>

      <!-- ---------------添加模块----------------------------------- -->
        <template>
            <el-dialog title="添加菜单" :visible.sync="dialogFormVisible" >
                <el-form >
                      <el-form-item label="标题" :label-width="formLabelWidth" required>
                          <el-input v-model="title" placeholder="请输入标题" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="图标" :label-width="formLabelWidth" required>
                        <el-input v-model="icon"  placeholder="请输入图标(base64格式)" maxlength="35" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="选中图标" :label-width="formLabelWidth" required>
                            <el-input v-model="icons" placeholder="请输入选中图标(base64格式)" maxlength="35" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="路径" :label-width="formLabelWidth" required>
                          <el-input  v-model="link" placeholder="请输入路径" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="排序" :label-width="formLabelWidth" required>
                          <el-input-number v-model="displayorder" controls-position="right" :min="0" autocomplete="off"  style="width: 100%;"/>
                      </el-form-item>
                      <el-form-item label="状态" :label-width="formLabelWidth" required>
                          <el-radio-group v-model="status">
                              <el-radio  label="0">暂停</el-radio>
                              <el-radio  label="1"> 开启</el-radio>
                          </el-radio-group>
                      </el-form-item>
                     
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="add()">确 定</el-button>
                </div>
            </el-dialog>
        </template>
       <!-- ---------------编辑模块-----------editForm--------:default-checked-keys =""---------------- -->
         <template>
            <el-dialog :title="title" :visible.sync="editFormVisible" width="600px" >
                <el-form ref="editForm" :model="editForm" :rules="rules" >
                      <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
                          <el-input v-model="editForm.title" placeholder="请输入标题" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="图标" :label-width="formLabelWidth" prop="icon">
                        <el-input v-model="editForm.icon"  placeholder="请输入图标(base64格式)"  autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="选中图标" :label-width="formLabelWidth" prop="icons">
                            <el-input v-model="editForm.icons" placeholder="请输入选中图标(base64格式)"  autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="路径" :label-width="formLabelWidth" prop="link">
                          <el-input v-model="editForm.link" placeholder="请输入路径" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="排序" :label-width="formLabelWidth" prop="displayorder">
                          <el-input-number v-model="editForm.displayorder" type="number" controls-position="right" :min="0" autocomplete="off"  style="width: 100%;"/>
                      </el-form-item>
                      <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
                          <el-radio-group v-model="editForm.status">
                              <el-radio  label="0">暂停</el-radio>
                              <el-radio  label="1"> 开启</el-radio>
                          </el-radio-group>
                      </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitForm">确 定</el-button>
                </div>
            </el-dialog>
        </template>

    <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}"
        >
        <el-table-column prop="title"   label="标题"  width="180"> </el-table-column>
        <el-table-column prop="icon"  label="图标">
            <template slot-scope="scope">
                <el-avatar shape="square" :size="30" :src="scope.row.icon"></el-avatar>
            </template>
        </el-table-column>
        <el-table-column prop="icons"  label="点击图标">
            <template slot-scope="scope">
                <el-avatar shape="square" :size="30" :src="scope.row.icons"></el-avatar>
            </template>
        </el-table-column>
        <el-table-column prop="link"  label="路径" :show-overflow-tooltip="true" width="200"> </el-table-column>
        <el-table-column prop="status"   label="状态" width="180">
            <template slot-scope="scope">
                <el-tag type="primary"  plain v-if="scope.row.status === '1'" >正常</el-tag>
                <el-tag type="danger"  plain v-else>停用</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)" v-hasPermi="['system:nav:edit']" >编辑</el-button>
                <el-button size="mini" type="text"  icon="el-icon-delete"   @click="handleDelete(scope.$index, scope.row)" v-hasPermi="['system:nav:remove']" >删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    
        <el-pagination center background layout="prev, pager, next, sizes, total, jumper" :page-sizes="pageSizes"
        :page-size="pagesize" :page-count="pageCount" :total="total" :current-page.sync="pageNo" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" ref="pagination" @next-click="pages" @prev-click="pages">
      </el-pagination>
    </div>
  
</template>



<script>
import request from '@/utils/request.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  export default {
   components: { Treeselect },
    data() {
      return {
        pagesize: 10, //分页默认一页显示15条数据
        currpage: 1, //分页默认显示第1页数据
        pageSizes:[10,30,50], //分页可选择一页多少条数据
        pageNo:1, //强行第一页
        total:0, //共多少条数据从接口获取
        pageCount:0, //共多少页数据从接口获取
        formLabelWidth: '120px',
        tableData: [],
        dialogFormVisible:false,
        editFormVisible:false,
        editForm:{
               title:'',
               icon:'',
               icons:'',
               link:'',
               displayorder:0,
               status:''
        },
        resetuserName:'',  //搜索名称
        resetphone:'',  //搜索电话
        resetstatus:'', //搜索状态
        title:'',
        icon:'',
        icons:'',
        link:'',
        displayorder:'',
        status:'',   //状态
        clickMsg:'1', //搜索数据分页和查询全部数据分页开关 1 查询所有数据  2 搜索数据  3 角色查询
        rules:{
          title:[
            { required: true, message: '请输入标题', trigger: 'change' }
          ],
          icon:[
            { required: true, message: '图标(base64格式)', trigger: 'change' }
          ],
          icons:[
            { required: true, message: '选中图标(base64格式)', trigger: 'change' }
          ],
          link:[ 
            { required: true, message: '请输入路径', trigger: 'change' }
          ],
          displayorder:[ 
            { required: true, message: '请输入排序', trigger: 'change' }
          ],
          status:[ 
            { required: true, message: '请选择状态', trigger: 'change' }
          ]
        }
      }
    },
    created() {
        this.info();
        
    },
     methods:{
       normalizer(node) {
                if (node.children && !node.children.length) {
                    delete node.children;
                }
                return {
                    id: node.deptId,
                    label: node.deptName,
                    children: node.children
                };
          },
  
 
       //获取列表数据  
      info(){
            this.clickMsg='1'; //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页
            let dats={
                pageNum:this.currpage,
                pageSize:this.pagesize
            }
            return request({
                  url:'nav/list',
                  method:'get',
                  params:dats
              }).then(res=>{
                if(res.status == 200){
                    this.tableData = res.data.data
                    // console.log(this.tableData)
                    this.pageCount = res.data.pageBean.pageCount; //接口获取到的共多少页
                    this.total = res.data.pageBean.count; //接口获取到的共多少条
                }
              })
         },
        
        addition(){
            this.title ='添加菜单'
            this.empty()
            this.resetForm("editForm");
            this.editFormVisible = true
        },
       
        //清空添加表单
        empty(){
            this.editForm ={
               title:'',
               icon:'',
               icons:'',
               link:'',
               displayorder:0,
               status:''
            }
        },
		//搜索
		handleQuery() {
		    this.clickMsg='2'; //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页
		    let dats={
		        pageNum:this.currpage,  
		        pageSize:this.pagesize,
            title:this.resetuserName,
            status:this.resetstatus,
		    }
		    return request({
		          url:'nav/list',
		          method:'get',
		          params:dats
		      }).then(res=>{
            if(res.status == 200){
                this.tableData = res.data.data
                this.pageCount = res.data.pageBean.pageCount; //接口获取到的共多少页
                this.total = res.data.pageBean.count; //接口获取到的共多少条
          
            }
		      })
		},
    
		//搜索重置
		resetQuery(){
			this.resetuserName = '',  //搜索名称
			this.resetphone = '',  //搜索电话
			this.resetstatus = '' //搜索状态
      this.info();
		},
    
    submitForm() {
     
        this.$refs['editForm'].validate((valid) => {
          console.log(valid)
          if (valid) {
            const form = this.editForm
            if(form.id !== undefined){
                this.clickMsg='3';
                let datas ={
                    id:this.editForm.id,
                    title:this.editForm.title,
                    icon:this.editForm.icon,
                    icons:this.editForm.icons,
                    link:this.editForm.link,
                    displayorder:this.editForm.displayorder,
                    status:this.editForm.status,
                }
                return request({
                    url:'nav/update',
                    method:'put',
                    data:datas
                }).then(res=>{
                if(res.status == 200){
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.info();
                    this.mineStatusValue = []
                    this.editFormVisible= false
                }
                }).catch(() => {
                    
                })
            }else{
              return request({
                    url:'nav/insert',
                    method:'post',
                    data:form
                }).then(res=>{
                if(res.status == 200){
                    this.$message({
                          message: '添加成功',
                          type: 'success'
                    });
                    this.info();
                    this.empty();
                    this.dialogFormVisible= false
                    
                }
                }).catch(() => {
                    
                })
            }
          } else {
           
            return false;
          }
        });
      },
        // 添加弹窗确定提交
        add(){
               let data={
                 title:this.title,
                 icon:this.icon,
                 icons:this.icons,
                 link:this.link,
                 displayorder:this.displayorder,
                 status:this.status,
               }
                
        
        },
         //编辑弹窗
        handleEdit(index, row) {
            // console.log(row)
           this.editFormVisible = true
           this.title ='编辑菜单'
           this.editForm = Object.assign({}, row)
        },
     
     
         // 编辑确定
        redactSubmint(){
          this.clickMsg='3';
            // console.log(this.editForm)
            let datas ={
                id:this.editForm.id,
                title:this.editForm.title,
                icon:this.editForm.icon,
                icons:this.editForm.icons,
                link:this.editForm.link,
                displayorder:this.editForm.displayorder,
                status:this.editForm.status,
            }
             return request({
                    url:'nav/update',
                    method:'put',
                    data:datas
                }).then(res=>{
                if(res.status == 200){
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.info();
                    this.mineStatusValue = []
                    this.editFormVisible= false
                }
                }).catch(() => {
                    
                })
        }, 
        //删除
          handleDelete(index, row) {
            // console.log(row);
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data ={
                    id:row.id
                }
                return request({
                    url:'nav/delete',
                    method:'delete',
                    data:data
                }).then(res=>{
                  if(res.status == 200){
                        this.$message({
                          message: '删除成功',
                          type: 'success'
                        });
                      this.info();
                  }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
         pages(){
            if(this.clickMsg=='1'){ //如果 clickMsg = 1 的话
               this.info(); //分页查询所有数据
            }else if(this.clickMsg=='2'){ //如果 clickMsg = 2 的话
               this.inquiry(); //分页查询搜索数据
            }else if(this.clickMsg=='3'){ //如果 clickMsg = 3 的话
               this.redactSubmint(); //分页角色查询数据
            }
         },

          /* 分页方法-------------- */
      handleCurrentChange(cpage) {
        this.currpage = cpage;
        this.pages(); //查询接口渲染数据
      },
      handleSizeChange(psize) {
        this.pagesize = psize;
        this.pages(); //查询接口渲染数据
      },
       
    },
  }
</script>

<style scoped>
  .el-table .warning-row {
     background: oldlace;
  }

  .el-table .success-row {
     background: #f0f9eb;
  }
  .tian{
        width: 100%;
        height: 60px;
   }
  .el-dialog{
      width: 500px !important;
  }
  .el-form{
        height: auto;
    }
  .el-pagination{
    text-align: right;
  }
</style>
 
